<template>
  <div class="container text-center" style="margin-top: 2rem;">
    <p>糟糕，您訪問的頁面不見了，去書架看看吧</p>
    <router-link to="/app/shelf">
      <button class="btn primary">我的書架</button>
    </router-link>
  </div>
</template>

<script>
export default {
  name: '',
  data () {
    return {}
  },
  created () {},
  methods: {
  }
}
</script>

<style type="text/scss" lang="scss">

</style>
